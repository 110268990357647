<template>
  <div class="login-img">
    <div class="longin">
      <div class="ms-title">智慧工会管理系统</div>
      <div>
        <!-- 账号 -->
        <div class="account">
          <div class="ioc">
            <svg
              style="width: 15px; color: #969a9f"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
              data-v-ba633cb8=""
            >
              <path
                fill="currentColor"
                d="M512 512a192 192 0 1 0 0-384 192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512 256 256 0 0 1 0 512zm320 320v-96a96 96 0 0 0-96-96H288a96 96 0 0 0-96 96v96a32 32 0 1 1-64 0v-96a160 160 0 0 1 160-160h448a160 160 0 0 1 160 160v96a32 32 0 1 1-64 0z"
              ></path>
            </svg>
          </div>
          <el-input v-model="input" placeholder="请输入会员编码"></el-input>
        </div>
        <!-- 密码 -->
        <div class="account">
          <div class="ioc">
            <svg
              style="width: 15px; color: #969a9f"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
              data-v-ba633cb8=""
            >
              <path
                fill="currentColor"
                d="M224 448a32 32 0 0 0-32 32v384a32 32 0 0 0 32 32h576a32 32 0 0 0 32-32V480a32 32 0 0 0-32-32H224zm0-64h576a96 96 0 0 1 96 96v384a96 96 0 0 1-96 96H224a96 96 0 0 1-96-96V480a96 96 0 0 1 96-96z"
              ></path>
              <path
                fill="currentColor"
                d="M512 544a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V576a32 32 0 0 1 32-32zm192-160v-64a192 192 0 1 0-384 0v64h384zM512 64a256 256 0 0 1 256 256v128H256V320A256 256 0 0 1 512 64z"
              ></path>
            </svg>
          </div>
          <el-input
            v-model="password"
            show-password
            placeholder="请输入密码"
          ></el-input>
        </div>
         <!-- 验证码 -->
        <div class="yard">
          <el-input type="number" class="port" v-model="rectify" placeholder="请输入验证码"></el-input>
          <img :src="'data:image/png;base64'+image" alt="" @click="conversion">
        </div>
        <!-- 登录 -->
        <div class="log">
          <el-button :plain="true" type="primary" @click="register"
            >登录</el-button
          >
        </div>
        <!-- 提示 -->
        <div class="hint">
          <p class="login-tips">提示 : 请填写正确的会员编码与密码</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted, onUnmounted } from "vue";
import { Login } from '../utils/api'
import qs from 'qs'
import { ElMessage } from "element-plus";
// 登录跳转路由
import { useRouter } from "vue-router";
export default {
  setup() {
    // 验证码变换
    const conversion=()=>{
      verify()
    }
    // 验证码答案编码
    const encipher=ref('')
    // 验证码图片
    const image=ref('')
    // 获取验证码
    const verify = ()=>{
      proxy
        .$axios({
          url: "https://interfaceadmin.juxiangyungong.com/admin/code",
          method: "POST",
        })
        .then((res)=>{
          image.value=res.data.data.img
          encipher.value=res.data.data.codeKey
          console.log(image.value)
          console.log(res)
        })
    }
    const router = useRouter();
    const input = ref("");
    const password = ref("");
    const rectify=ref(null)
    // 登录
    const { proxy } = getCurrentInstance();
    const register = () => {
      proxy
        .$axios({
          url: "https://interfaceadmin.juxiangyungong.com/admin/login",
          method: "POST",
          data:{
            userName:input.value,
            password:password.value,
            codeKey:encipher.value,
            codeValue:rectify.value
          }
        })
        .then((res)=>{
            if(res.data.code==0){
            sessionStorage.setItem('token', res.data.data)
            router.push("/homepage");
            ElMessage({
                message: "登录成功",
                type: "success",
                center: true,
              });
          }else if(input.value==''){
            ElMessage({
              message: '会员编码不能为空',
              type: "warning",
              center: true,
            });
            verify()
          }else if(password.value==''){
            ElMessage({
                message: '密码不能为空',
                type: "warning",
                center: true,
              });
              verify()
          }else if(res.data.code==2001){
            ElMessage({
              message: res.data.msg,
              type: "error",
              center: true,
            });
            verify()
          }
          else if(res.data.code==201){
            ElMessage({
                message: '验证码不能为空',
                type: "warning",
                center: true,
              });
              verify()
              rectify.value=''
          }else if(res.data.code==600){
            ElMessage({
              message: res.data.msg,
              type: "error",
              center: true,
            });
            verify()
            rectify.value=''
            rectify.value=''
          }else if(res.data.code==2005){
            ElMessage({
              message: res.data.msg,
              type: "error",
              center: true,
            });
            verify()
            rectify.value=''
          }else if(res.data.code==2002){
            ElMessage({
              message: res.data.msg,
              type: "error",
              center: true,
            });
            verify()
            rectify.value=''
          }else if(res.data.code==2008){
            ElMessage({
              message: res.data.msg,
              type: "error",
              center: true,
            });
            verify()
            rectify.value=''
          }
          console.log(res)
        })


        
      // const data={
      //   userName:input.value,
      //   password:password.value,
      //   codeKey:encipher.value,
      //   codeValue:rectify.value
      // }
      // console.log(qs.parse(data)) 
      // Login(qs.parse(data)).then((res)=>{
      //   console.log(res)
      //   if(res.data.code==0){
      //     sessionStorage.setItem('token', res.data.data)
      //     router.push("/homepage");
      //     ElMessage({
      //         message: "登录成功",
      //         type: "success",
      //         center: true,
      //       });
      //   }else if(input.value==''){
      //     ElMessage({
      //       message: '会员编码不能为空',
      //       type: "warning",
      //       center: true,
      //     });
      //     verify()
      //   }else if(password.value==''){
      //     ElMessage({
      //         message: '密码不能为空',
      //         type: "warning",
      //         center: true,
      //       });
      //       verify()
      //   }else if(res.data.code==2001){
      //     ElMessage({
      //       message: res.data.msg,
      //       type: "error",
      //       center: true,
      //     });
      //     verify()
      //   }
      //   else if(res.data.code==201){
      //     ElMessage({
      //         message: '验证码不能为空',
      //         type: "warning",
      //         center: true,
      //       });
      //       verify()
      //       rectify.value=''
      //   }else if(res.data.code==600){
      //     ElMessage({
      //       message: res.data.msg,
      //       type: "error",
      //       center: true,
      //     });
      //     verify()
      //     rectify.value=''
      //     rectify.value=''
      //   }else if(res.data.code==2005){
      //     ElMessage({
      //       message: res.data.msg,
      //       type: "error",
      //       center: true,
      //     });
      //     verify()
      //     rectify.value=''
      //   }else if(res.data.code==2002){
      //     ElMessage({
      //       message: res.data.msg,
      //       type: "error",
      //       center: true,
      //     });
      //     verify()
      //     rectify.value=''
      //   }else if(res.data.code==2008){
      //     ElMessage({
      //       message: res.data.msg,
      //       type: "error",
      //       center: true,
      //     });
      //     verify()
      //     rectify.value=''
      //   }
      // })


    };
     // 回车登录
    onMounted(() => {
      window.addEventListener("keydown", methods.enterLogin);
      // location. reload() 
      verify()
    });
    const methods = {
      enterLogin(e) {
        if(e.keyCode===13){
          proxy
        .$axios({
          url: "https://interfaceadmin.juxiangyungong.com/admin/login",
          method: "POST",
          data:{
            userName:input.value,
            password:password.value,
            codeKey:encipher.value,
            codeValue:rectify.value
          }
        })
        .then((res)=>{
            if(res.data.code==0){
            sessionStorage.setItem('token', res.data.data)
            router.push("/homepage");
            ElMessage({
                message: "登录成功",
                type: "success",
                center: true,
              });
          }else if(input.value==''){
            ElMessage({
              message: '会员编码不能为空',
              type: "warning",
              center: true,
            });
            verify()
          }else if(password.value==''){
            ElMessage({
                message: '密码不能为空',
                type: "warning",
                center: true,
              });
              verify()
          }else if(res.data.code==2001){
            ElMessage({
              message: res.data.msg,
              type: "error",
              center: true,
            });
            verify()
          }
          else if(res.data.code==201){
            ElMessage({
                message: '验证码不能为空',
                type: "warning",
                center: true,
              });
              verify()
              rectify.value=''
          }else if(res.data.code==600){
            ElMessage({
              message: res.data.msg,
              type: "error",
              center: true,
            });
            verify()
            rectify.value=''
            rectify.value=''
          }else if(res.data.code==2005){
            ElMessage({
              message: res.data.msg,
              type: "error",
              center: true,
            });
            verify()
            rectify.value=''
          }else if(res.data.code==2002){
            ElMessage({
              message: res.data.msg,
              type: "error",
              center: true,
            });
            verify()
            rectify.value=''
          }else if(res.data.code==2008){
            ElMessage({
              message: res.data.msg,
              type: "error",
              center: true,
            });
            verify()
            rectify.value=''
          }
          console.log(res)
        })






      //     const data={
      //       userName:input.value,
      //       password:password.value,
      //       codeKey:encipher.value,
      //       codeValue:rectify.value
      //     }
      // Login(qs.parse(data)).then((res)=>{
      //   console.log(res)
      //   if(res.data.code==0){
      //     sessionStorage.setItem('token', res.data.data)
      //     router.push("/homepage");
      //     ElMessage({
      //         message: "登录成功",
      //         type: "success",
      //         center: true,
      //       });
      //   }else if(input.value==''){
      //     ElMessage({
      //       message: '会员编码不能为空',
      //       type: "warning",
      //       center: true,
      //     });
      //     verify()
      //   }else if(password.value==''){
      //     ElMessage({
      //         message: '密码不能为空',
      //         type: "warning",
      //         center: true,
      //       });
      //       verify()
      //   }else if(res.data.code==2001){
      //     ElMessage({
      //       message: res.data.msg,
      //       type: "error",
      //       center: true,
      //     });
      //     verify()
      //   }
      //   else if(res.data.code==201){
      //     ElMessage({
      //         message: '验证码不能为空',
      //         type: "warning",
      //         center: true,
      //       });
      //       verify()
      //       rectify.value=''
      //   }else if(res.data.code==600){
      //     ElMessage({
      //       message: res.data.msg,
      //       type: "error",
      //       center: true,
      //     });
      //     verify()
      //     rectify.value=''
      //   }else if(res.data.code==2005){
      //     ElMessage({
      //       message: res.data.msg,
      //       type: "error",
      //       center: true,
      //     });
      //     verify()
      //     rectify.value=''
      //   }else if(res.data.code==2002){
      //     ElMessage({
      //       message: res.data.msg,
      //       type: "error",
      //       center: true,
      //     });
      //     verify()
      //     rectify.value=''
      //   }else if(res.data.code==2008){
      //     ElMessage({
      //       message: res.data.msg,
      //       type: "error",
      //       center: true,
      //     });
      //     verify()
      //     rectify.value=''
      //   }
      // })
        }
      },
    };
    // 销毁回车事件
    onUnmounted(()=>{
      window.removeEventListener("keydown", methods.enterLogin, false)
    })
    return {
      conversion,
      rectify,
      image,
      input,
      password,
      register,
    };
  },
};
</script>

<style scoped lang='scss'>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.port{
  width: 50%;
}
.yard{
  width: 290px;
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.hint {
  margin: 0 auto;
  width: 290px;
  font-size: 12px;
  line-height: 30px;
  color: 	#B5B5B5;
  margin-top: 10px;
  margin-bottom: 30px;
}
.el-button {
  height: 40px;
  width: 290px;
  background-color: #409eff;
  color: #ffff;
  border-color: #409eff;
}
.log {
  margin: 0 auto;
  width: 290px;
  margin-top: 20px;
}
::v-deep .el-input__inner {
  height: 40px;
  border-radius: 0 4px 4px 0 !important;
  box-shadow: none !important;
}
.ioc {
  width: 70px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f5f7fa;
  border-radius: 4px 0 0 4px;
  border-right: 1px solid #dcdfe6;
}
.account {
  margin: 0 auto;
  margin-bottom: 20px;
  width: 290px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
}
.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color:  	#8B8B7A;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}
.longin {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.7);
  overflow: hidden;
}
.login-img {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("../assets/log.jpg");
  background-size: 100%;
}
</style>